import { useForm, usePage } from "@inertiajs/react";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import WebpImage from "../WebpImage";
import { ChangeEvent, FormEventHandler, useEffect, useState } from "react";
import { InertiaPage } from "@/types/inertia";
import Modal from "../Modal";

const ContactForm = ({ smallerMargin = false, apartmentId = '', apartmentName = '' }: { smallerMargin?: boolean; apartmentId?: string; apartmentName?: string }) => {
    const { footerInfo } = usePage<InertiaPage>().props;

    const { data, setData, processing, post, errors, recentlySuccessful, reset } = useForm({
        name_surname: '',
        email: '',
        phone: '',
        message: apartmentId ? `Jestem zainteresowany mieszkaniem nr ${apartmentName}.\n\nProszę o kontakt w celu przedstawienia oferty.` : '',
        marketing_acceptance: false,
        apartment_id: apartmentId
    });

    const [canSendForm, setCanSendForm] = useState<boolean>(true);
    const [successfulSent, setSuccessfulSent] = useState<boolean>(false);

    useEffect(() => {
        if (data.name_surname != '' && data.email != '' && data.phone != '' && data.message != '') {
            setCanSendForm(true);
        } else {
            setCanSendForm(false);
        }
    }, [data])

    const sendContactForm: FormEventHandler = (e) => {
        e.preventDefault();

        try {
            post(route('sendContactForm'), {
                preserveScroll: true,
                onSuccess: () => {
                    setTimeout(() => {
                        reset()
                        setSuccessfulSent(true);
                    }, 1500)
                },
            });
        } catch (e) {

        }
    }

    const closeModal = () => {
        setSuccessfulSent(false);
    }

    return (
        <section id="kontakt" className={`${smallerMargin ? 'lg:mb-24 mb-14' : 'lg:mb-26 mb-22'} maxMd:bg-white maxMd:relative maxMd:z-[9999999]`}>
            <Modal
                show={successfulSent}
                maxWidth="xl"
                closeable={true}
                onClose={closeModal}
            >
                <div className="p-6 py-10 text-center">
                    <p className="text-primary text-h4 leading-h4 mb-8 font-bold uppercase">Dziękujemy za przesłanie zapytania</p>
                    <p className="text-text text-p leading-p mb-8">Wkrótce skontaktujemy się z Państwem.</p>
                    <button type="button" className="button-primary w-full mt-8" onClick={closeModal}>Powrót</button>
                </div>
            </Modal>
            <Column xl={12} lg={12}>
                {successfulSent &&
                    <p className="text-label leading-label text-primary uppercase font-extrabold">Pomyślnie przesłano formularz kontaktowy</p>
                }
            </Column>
            <Container>
                <Row>
                    <Column>
                        <hr className="border-secondary mb-22 maxMd:mb-14" />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <div className="flex lg:gap-x-10 items-center lg:mb-14 mb-4 gap-x-0">
                            <img src="/storage/static/kusocinskiego-signet.svg" alt="Kusocińskiego - sygnet" width={112} height={112} className="lg:block hidden" />
                            <p className="text-h2 leading-h2 maxMd:text-h3 maxMd:leading-h3 uppercase font-bold text-primary">Masz DO nas Pytania?<br /> <span className="text-secondary">Skontaktuj się z nami!</span></p>
                        </div>
                    </Column>
                </Row>
                <div className="flex items-start flex-wrap maxLg:justify-center lg:gap-x-[112px] mb-22 maxLg:mt-14">
                    <div className="flex gap-x-10 flex-wrap items-center maxLg:w-full maxLg:mb-12 maxLg:text-center maxLg:justify-center">
                        <WebpImage
                            width={160}
                            height={160}
                            fallback="/storage/static/patrycja-goch.png"
                            lazy
                            alt="Patrycja Goch"
                            className="w-40 h-40 rounded-full maxLg:mb-6 maxLg:mx-auto"
                        />
                        <div className="lg:max-w-[calc(100%_-_200px)] max-w-full w-full">
                            <p className="text-h4 leading-h4 text-text font-extrabold uppercase mb-1">
                                Patrycja Goch
                            </p>
                            <a className="text-text tracking-[0.45px] text-p" href="tel:+48 573 860 781">+48 573 860 781</a>
                        </div>
                    </div>
                    <div className="flex gap-x-10 flex-wrap items-center maxLg:w-full maxLg:text-center maxLg:justify-center">
                        <WebpImage
                            width={160}
                            height={160}
                            fallback="/storage/static/tomasz-tauroginski.png"
                            lazy
                            alt="TOMASZ TAUROGIŃSKI"
                            className="w-40 h-40 rounded-full maxLg:mb-6 maxLg:mx-auto"
                        />
                        <div className="lg:max-w-[calc(100%_-_200px)] max-w-full w-full">
                            <p className="text-h4 leading-h4 text-text font-extrabold uppercase mb-1">
                                TOMASZ TAUROGIŃSKI
                            </p>
                            <a className="text-text tracking-[0.45px] text-p" href="tel:+48 507 412 299">+48 507 412 299</a>
                        </div>
                    </div>
                </div>
                <form onSubmit={sendContactForm} id="contact-form">
                    <Row className="justify-between">
                        <Column xl={6} lg={6} className="lg:pr-14">
                            <Row>
                                <Column xl={12} lg={12}>
                                    {errors.name_surname &&
                                        <p className={`text-i-red text-label mb-1`}>{errors.name_surname}</p>
                                    }
                                    <input type="text" value={data.name_surname} className={`contact-input ${errors.name_surname ? 'with-error' : ''}`} name="name_surname" placeholder="Imię i nazwisko*" onChange={(e: ChangeEvent<HTMLInputElement>) => setData('name_surname', e.currentTarget.value)} />

                                    {errors.email &&
                                        <p className={`text-i-red text-label mb-1`}>{errors.email}</p>
                                    }
                                    <input type="text" value={data.email} className={`contact-input ${errors.email ? 'with-error' : ''}`} name="email" placeholder="Adres e-mail*" onChange={(e: ChangeEvent<HTMLInputElement>) => setData('email', e.currentTarget.value)} />

                                    {errors.phone &&
                                        <p className={`text-i-red text-label mb-1`}>{errors.phone}</p>
                                    }
                                    <input type="text" value={data.phone} className={`contact-input ${errors.phone ? 'with-error' : ''}`} name="phone" placeholder="Numer telefonu*" onChange={(e: ChangeEvent<HTMLInputElement>) => setData('phone', e.currentTarget.value)} />
                                    {errors.message &&
                                        <p className={`text-i-red text-label mb-1`}>{errors.message}</p>
                                    }
                                    <textarea name="message" value={data.message} id="" rows={9} className={`contact-input ${errors.message ? 'with-error' : ''}`} placeholder="Treść wiadomości*" onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setData('message', e.currentTarget.value)}></textarea>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <p className="text-black text-label leading-label">Przesyłając wiadomość zapoznaj się z <a className="text-secondary" href="/polityka-prywatnosci-kusocinskiego.pdf" target="_blank">Polityką prywatności (ochrony danych osobowych).</a></p>
                                </Column>
                            </Row>
                            <Row>
                                <Column xl={12} lg={12} className="my-6">
                                    <input type="checkbox" id="marketing_acceptance" className="hidden contact-input-check" onChange={(e: ChangeEvent<HTMLInputElement>) => setData('marketing_acceptance', e.currentTarget.checked ? true : false)} />
                                    <label htmlFor="marketing_acceptance" className="text-black text-label leading-label contact-label-check relative">Wyrażam zgodę na otrzymywanie od Unidevelopment S.A. drogą elektroniczną na mój adres e-mail ofert sprzedaży i najmu lokali oraz innych związanych z tym usług oferowanych przez Unidevelopment S.A. oraz inne podmioty z grupy Unidevelopment S.A.</label>
                                </Column>
                                <Column xl={12} lg={12}>
                                    <p className="text-black text-label leading-label maxMd:mt-6">Przysługuje Pani/Panu prawo do cofnięcia powyższej w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania dokonanego na podstawie zgody przed jej cofnięciem.</p>
                                </Column>
                            </Row>
                            <Row className="justify-end items-center">
                                <Column xl={12} lg={12}>
                                    <button disabled={!canSendForm && !processing} type="submit" className="button-primary w-full mt-8">Wyślij wiadomość</button>
                                </Column>
                            </Row>
                        </Column>
                        <Column xl={6} lg={6} className="lg:pl-14 maxLg:mt-18">
                            <p className="text-h4 leading-h4 font-extrabold text-text uppercase tracking-[0.6px] mb-2">Biuro Sprzedaży mieszkań</p>
                            <p className="text-text text-p mb-6"><strong>Przy terenie budowy</strong> <br /> ul. J. Kusocińskiego 80-288 Gdańsk</p>
                            <WebpImage
                                width={371}
                                height={190}
                                fallback="/storage/static/biuro-mapa.jpg"
                                lazy
                                alt="Mapa"
                                className="lg:max-w-[370px] h-auto mb-2"
                            />
                            <a href="https://maps.app.goo.gl/Edw1YKVGBUNW5Emh8" target="_blank" className="text-primary text-p leading-p tracking-small font-extrabold block mb-6">Sprawdź, jak do nas dotrzeć</a>
                            <div className='mb-2'>
                                <p className='text-h4 leading-h4 font-extrabold text-text uppercase tracking-[0.6px] mb-2'>Dane kontaktowe</p>
                                <p className='text-text text-p leading-label tracking-[0.07px] font-extrabold mb-1 uppercase'>
                                    Telefon
                                </p>
                                <a href="tel:+48 664 087 645" title='+48 664 087 645' className='text-text text-p leading-p tracking-small'>
                                    +48 664 087 645
                                </a>
                            </div>
                            <p className='text-text text-p leading-label tracking-[0.07px] font-extrabold mb-1 uppercase'>
                                E-mail
                            </p>
                            <a href="mailto:kusocinskiego@unidevelopment.pl" title='kusocinskiego@unidevelopment.pl' className='text-text text-p leading-p tracking-small'>
                                kusocinskiego@unidevelopment.pl
                            </a>
                            <p className='text-h4 leading-h4 font-extrabold text-text uppercase tracking-[0.6px] mb-2 mt-6'>Godziny pracy</p>
                            <ul className="flex flex-wrap lg:[&>li]:w-1/2">
                                <li className='text-text text-p leading-p tracking-small mb-1'>
                                    Poniedziałek: 10:00 - 18:00
                                </li>
                                <li className='text-text text-p leading-p tracking-small mb-1'>
                                    Wtorek: 12:00 - 18:00
                                </li>
                                <li className='text-text text-p leading-p tracking-small mb-1'>
                                    Środa: 10:00 - 18:00
                                </li>
                                <li className='text-text text-p leading-p tracking-small mb-1'>
                                    Czwartek: 10:00 - 18:00
                                </li>
                                <li className='text-text text-p leading-p tracking-small mb-1'>
                                    Piątek: 08:00 - 16:00
                                </li>
                                <li className='text-text text-p leading-p tracking-small mb-1'>
                                    Sobota: 09:00 - 14:00
                                </li>
                            </ul>
                            <div className='font-extrabold text-primary leading-p mb-4 text-p tracking-p maxMd:mb-0'>
                                {footerInfo && footerInfo.content &&
                                    <p dangerouslySetInnerHTML={{ __html: footerInfo.content.replace(/\n/g, '<br/>') }} />
                                }
                            </div>
                        </Column>
                    </Row>
                </form>
            </Container>
        </section>
    )
}

export default ContactForm;